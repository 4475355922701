import React from "react";

const NotFoundPage = () => (
    <>
        <h1>Страница не найдена</h1>
        <a href="/">вернуться на главную</a>
    </>
);

export default NotFoundPage;
